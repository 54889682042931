"use client";

/**
 * Third-party libraries.
 */
import { ApolloClient, ApolloProvider as ApolloProviderComponent, createHttpLink, InMemoryCache, split } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { useMemo } from "react";

/**
 * Project components.
 */
import { useNetworkContext } from "@/components/client/network";
import { DateUtility } from "@/components/common/utilities";
import { useAuthenticationContext } from "../../authentication";

// Adds messages only in a dev environment
loadDevMessages();
loadErrorMessages();

/**
 * Project components.
 */

/**
 * Provides the Apollo client contexts. Put this on the top
 */
const ApolloClientProvider = ({
  children
}: React.PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    setGraphqlConnected,
    setGraphqlLoading
  } = useNetworkContext();
  const {
    user
  } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================
  /**
   * Apollo Web Socket link which specifies the GraphQL server endpoint.
   */
  const webSocketLink = useMemo(() => /** Only use the web socket if the user is logged in */
  typeof window === "undefined" || !user ? null : new GraphQLWsLink(createClient({
    url: process.env.NEXT_PUBLIC_APOLLO_GRAPHQL_WEB_SOCKET_ENDPOINT,
    /**
     * Include the user ID upon successful connection
     * so that the server knows which user is connected.
     *
     * This will be used for updating the user availability status to `OFFLINE`.
     */
    connectionParams: {
      userId: user.id
    },
    // shouldRetry: () => true,
    // keepAlive: 3000,
    on: {
      opened: () => {
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket opened.");
        setGraphqlConnected(true);
        setGraphqlLoading(false);
      },
      connected: socket => {
        // activeSocket = socket;
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket established.", socket);
        setGraphqlConnected(true);
        setGraphqlLoading(false);
      },
      closed: () => {
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket closed.");
        setGraphqlConnected(false);
        setGraphqlLoading(false);
      },
      connecting: () => {
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket connecting.");
        setGraphqlConnected(false);
        setGraphqlLoading(true);
      },
      error: error => {
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket error: ", error);
        setGraphqlConnected(false);
        setGraphqlLoading(false);
      },
      message: message => {
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket message: ", message);
      },
      ping: received => {
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket ping.");
      },
      pong: () => {
        console.log(DateUtility.getDateTime({
          date: new Date()
        }) + " - Apollo GraphQL WebSocket pong.");
      }
    }
  })), [setGraphqlConnected, setGraphqlLoading, user]);

  /**
   * Apollo HTTP link which specifies the GraphQL server endpoint.
   */
  const httpLink = useMemo(() => createHttpLink({
    uri: process.env.NEXT_PUBLIC_APOLLO_GRAPHQL_ENDPOINT,
    credentials: "same-origin"
  }), []);

  /**
   * Apollo Authentication link that appends the Auth0 authentication token to
   * the Graphql request header.
   */
  const authLink = useMemo(() => setContext(async (_, {
    headers
  }) => {
    return {
      headers: {
        ...headers
        // authorization: auth0Token ? `Bearer ${auth0Token}` : "",
      }
    };
  }), []);

  /**
   * The split function takes three parameters:
   * - A function that's called for each operation to execute
   * - The Link to use for an operation if the function returns a "truthy" value
   * - The Link to use for an operation if the function returns a "falsy" value
   *
   */
  const splitLink = useMemo(() => webSocketLink === null ? httpLink : split(({
    query
  }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  }, webSocketLink, httpLink), [httpLink, webSocketLink]);

  /**
   * Apollo client.
   */
  const client = useMemo(() => new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only"
      },
      mutate: {
        fetchPolicy: "network-only"
      },
      query: {
        fetchPolicy: "network-only"
      }
    },
    link: authLink.concat(splitLink),
    name: process.env.NEXT_PUBLIC_APOLLO_CLIENT_NAME,
    version: "0.0.0"
  }), [authLink, splitLink]);
  return <ApolloProviderComponent client={client} data-sentry-element="ApolloProviderComponent" data-sentry-component="ApolloClientProvider" data-sentry-source-file="apollo-client-provider.tsx">
      {children}
    </ApolloProviderComponent>;
};
export default ApolloClientProvider;